import { FormEvent, useEffect, useRef, useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { LoginRequest } from "../../../api/identity.api";
import FormInput from "../../../components/form-input/FormInput";
import { loginUser } from "../../../store/features/identity.slice";
import { AppDispatch, RootState } from "../../../store/store";
import styles from "./Login.styles"

export default function Login() {
    const { isAuthenticated } = useSelector((state: RootState) => state.identity)
    const [ errors, setErrors ] = useState<any>({});
    const [ submitAttempted, setSubmitAttempted ] = useState(false)

    const form = useRef<HTMLFormElement>(null);
    const dispatch = useDispatch<AppDispatch>();
    const location = useLocation();
    const navigate = useNavigate();

    async function submitLogin(event: FormEvent) {
        event.preventDefault();
        const formData = new FormData(form.current as HTMLFormElement);
        const request: LoginRequest = {
            username: formData.get("username") as string,
            password: formData.get("password") as string
        }

        await dispatch(loginUser(request)).unwrap()
            .then(() => {
                toast.info(`Welcome ${request?.username}!`)
            })
            .catch(error => {
                setErrors(error?.errors);
            })
            .finally(() => {
                setSubmitAttempted(true);
            })
    }

    useEffect(() => {
        if (isAuthenticated) {
            const locationState = location.state as any;
            const from = locationState?.from?.pathname || "/";

            navigate(from);
        }
    }, [isAuthenticated, location, navigate])

    return (
        <Card style={styles.LoginWindow} onSubmit={submitLogin}>
            <Form ref={form}>
                <Form.Group className="mb-3">
                    <FormInput
                        type="text"
                        label="Username"
                        name="username"
                        errors={errors?.UserName}
                        submitted={submitAttempted}
                    />
                </Form.Group>
                <Form.Group className="mb-3">
                    <FormInput
                        type="password"
                        label="Password"
                        name="password"
                        errors={errors?.Password}
                        submitted={submitAttempted}
                    />
                </Form.Group>
                <Button variant="primary" type="submit">
                    Login
                </Button>
                <span className="ml-auto">
                    Don't have an account?
                    <Link to="/auth/register">Register</Link>
                </span>
            </Form>
        </Card>
    )
}