import { Navbar, Container, Nav, Button } from "react-bootstrap";
import { logout } from "../../store/features/identity.slice";
import { useAppDispatch } from "../../store/store";
import { LinkContainer } from "react-router-bootstrap";

export default function AppNavbar() {
  const dispatch = useAppDispatch();

  return (
    <Navbar bg="dark" variant="dark">
      <Container>
        <LinkContainer to="/">
          <Navbar.Brand>Snitcheroo</Navbar.Brand>
        </LinkContainer>
        <Nav className="me-auto">
        </Nav>
        <Button variant='primary'
          onClick={() => dispatch(logout())}>
          Log Out
        </Button>
      </Container>
    </Navbar>
  )
}