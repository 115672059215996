import { Form } from "react-bootstrap";

export interface Props {
    name?: string;
    type?: "text" | "password" | "number";
    label?: string;
    placeholder?: string;
    submitted?: boolean;
    errors?: string[];
    value?: any;
    onChange?: (e: any) => void;
}

export default function FormInput(props: Props) {
    // props.name = props.name ?? props.label?.replace(' ', '').toLowerCase()
    return (
        <>
            {!!props.label
                ? (<Form.Label>{props.label}</Form.Label>)
                : ("")
            }
            <Form.Control 
                type={props.type} 
                placeholder={props.placeholder}
                name={props.name} 
                value={props.value}
                onChange={props.onChange}
                isValid={props.submitted && !props.errors}
                isInvalid={!!props.errors}
            />
            <Form.Control.Feedback type="invalid">
                {props.errors?.map((err: string) => (
                    <span>{err}<br /></span>
                ))}
            </Form.Control.Feedback>
        </>
    )
}