import { useEffect, useState } from "react";
import { Accordion, Button, ListGroup, ListGroupItem, Tab, Table, Tabs } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom"
import AddRuleModal from "../../components/add-rule-modal/AddRuleModal";
import AddSnitchModal from "../../components/add-snitch-modal/AddSnitchModal";
import { enterRoom } from "../../store/features/rooms.slice";
import { RootState, useAppDispatch } from "../../store/store";
import styles from "./Room.styles";

export default function Room() {
    const { id } = useParams<"id">();
    const { currentRoom } = useSelector((state: RootState) => state.rooms);
    const [addRuleModalShow, setAddRuleModalShow] = useState(false);
    const [addSnitchModalShow, setAddSnitchModalShow] = useState(false);
    const [addSnitchUserId, setAddSnitchUserId] = useState<string | undefined>();

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const openAddSnitchModal = (userId: string) => {
        setAddSnitchUserId(userId);
        setAddSnitchModalShow(true);
    }

    useEffect(() => {
        if (id) {
            dispatch(enterRoom(id))
        } else {
            navigate("/");
        }
    }, [dispatch, id, navigate])

    if (!currentRoom) {
        return (<></>);
    }

    return (
        <>
            <div style={styles.RoomName}>{currentRoom.name}</div>
            <div style={styles.RoomId}>
                <span style={styles.RoomId.Label}>ID</span>
                <span style={styles.RoomId.Value}>{currentRoom.id}</span>
            </div>

            <Tabs defaultActiveKey="users" className="mb-3">
                <Tab eventKey="users" title="Users">
                    {currentRoom.users.length === 0
                        ? (<h2>No users in the room</h2>)
                        : (
                            <>
                                <Accordion>
                                    {currentRoom.users.map(user => (
                                        <Accordion.Item eventKey={user.id}>
                                            <Accordion.Header>
                                                <div>
                                                    {user.userName}
                                                </div>
                                                <div style={{ marginLeft: "auto" }}>
                                                    {user.score}
                                                </div>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <Button onClick={() => openAddSnitchModal(user.id)}>Snitch!</Button>
                                                {user.snitches.length === 0
                                                    ? (<h4>Dude ain't no snitch</h4>)
                                                    : (
                                                        <>
                                                            <ListGroup>
                                                                {user.snitches.map(snitch => (
                                                                    <ListGroupItem>
                                                                        <span>{snitch.points}</span>
                                                                        {snitch.description}
                                                                    </ListGroupItem>
                                                                ))}
                                                            </ListGroup>
                                                        </>
                                                    )
                                                }
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    ))}

                                </Accordion>
                            </>
                        )
                    }

                    <AddSnitchModal
                        show={addSnitchModalShow}
                        onHide={() => setAddSnitchModalShow(false)}
                        userId={addSnitchUserId}
                    />
                </Tab>
                <Tab eventKey="rules" title="Rules">
                    {currentRoom.rules.length === 0
                        ? (<h2>No rules in the room yet</h2>)
                        : (
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>Points</th>
                                        <th>Description</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentRoom.rules.map(rule => (
                                        <tr key={rule.id}>
                                            <td>{rule.points}</td>
                                            <td>{rule.description}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        )
                    }

                    <Button onClick={() => setAddRuleModalShow(true)}>Add rule</Button>

                    <AddRuleModal
                        show={addRuleModalShow}
                        onHide={() => setAddRuleModalShow(false)}
                        roomId={currentRoom.id}
                    />
                </Tab>
            </Tabs>

        </>
    )
}