import { RoomListItem, RoomDetails, RoomRule, RoomUser } from "../models/rooms.models";
import http from "./http";

export interface CreateRoomRequest {
  name: string
}

function createRoom(request: CreateRoomRequest): Promise<RoomListItem> {
  return http.post<RoomListItem>("/rooms/create", request).then(res => res.data);
}

export interface JoinRoomRequest {
  id: string | null
}

function joinRoom(request: JoinRoomRequest): Promise<RoomListItem> {
  return http.put<RoomListItem>("/rooms/join", request).then(res => res.data);
}

function getMyRooms(): Promise<RoomListItem[]> {
  return http.get<RoomListItem[]>("/rooms/my").then(res => res.data);
}

function getRoomDetails(roomId: string): Promise<RoomDetails> {
  return http.get<RoomDetails>(`/rooms/${roomId}`).then(res => res.data);
}

export interface AddRoomRuleRequest {
  description: string,
  points: number
}

function addRoomRule(roomId: string, request: AddRoomRuleRequest) {
  return http.post<RoomRule>(`/rooms/${roomId}/rules`, request).then(res => res.data);
}

function getUserDetailsForRoom(roomId: string, userId: string) {
  return http.get<RoomUser>(`/rooms/${roomId}/users/${userId}`).then(res => res.data);
}

const roomsApi = {
  createRoom,
  joinRoom,
  getMyRooms,
  getRoomDetails,
  addRoomRule,
  getUserDetailsForRoom
}

export default roomsApi;