import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import roomsApi, { AddRoomRuleRequest, CreateRoomRequest, JoinRoomRequest } from "../../api/rooms.api";
import snitchesApi, { AddSnitchRequest } from "../../api/snitches.api";
import { RoomDetails, RoomListItem, RoomRule, RoomUser } from "../../models/rooms.models";

export interface RoomsState {
  rooms: RoomListItem[];
  currentRoom: RoomDetails | null;
}

const initialState: RoomsState = {
  rooms: [],
  currentRoom: null
}

export const createRoom = createAsyncThunk<
  RoomListItem,
  CreateRoomRequest
>('rooms/create', async (request, { rejectWithValue }) => {
  try {
    return await roomsApi.createRoom(request);
  }
  catch (error: any) {
    const data = error?.response?.data;
    const errorObject = data?.errors ? data : { errors: data };
    return rejectWithValue(errorObject);
  }

})

export const joinRoom = createAsyncThunk<
  RoomListItem,
  JoinRoomRequest
>('rooms/join', async (request, { rejectWithValue }) => {
  try {
    return await roomsApi.joinRoom(request);
  }
  catch (error: any) {
    return rejectWithValue(error?.response?.data);
  }
})

export const getMyRooms = createAsyncThunk<
  RoomListItem[]
>('rooms/getMy', async (_, { rejectWithValue }) => {
  try {
    return await roomsApi.getMyRooms();
  }
  catch (error: any) {
    return rejectWithValue(error?.response?.data);
  }
})

export const enterRoom = createAsyncThunk<
  RoomDetails,
  string
>('rooms/enter', async (roomId, { rejectWithValue }) => {
  try {
    return await roomsApi.getRoomDetails(roomId);
  }
  catch (error: any) {
    return rejectWithValue(error?.response?.data);
  }
})

export const addRoomRule = createAsyncThunk<
  { roomId: string, newRule: RoomRule },
  { roomId: string, request: AddRoomRuleRequest }
>('rooms/rules/add', async ({ roomId, request }, { rejectWithValue }) => {
  try {
    const result = await roomsApi.addRoomRule(roomId, request);
    return {
      roomId,
      newRule: result
    }
  }
  catch (error: any) {
    return rejectWithValue(error?.response?.data);
  }
})

export const addSnitch = createAsyncThunk<
  { roomId: string, user: RoomUser},
  AddSnitchRequest
>('rooms/snitches/add', async (request, { getState, dispatch, rejectWithValue }) => {
  try {
    await snitchesApi.addSnitch(request);
    const result = await roomsApi.getUserDetailsForRoom(request.roomId, request.userId);
    return {
      roomId: request.roomId,
      user: result
    }
  }
  catch (error: any) {
    return rejectWithValue(error?.response?.data);
  }
})

export const roomsSlice = createSlice({
  name: 'rooms',
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    builder
      .addCase(createRoom.fulfilled, (state, action) => {
        state.rooms = [
          ...state.rooms,
          action.payload
        ];
      })
      .addCase(joinRoom.fulfilled, (state, action) => {
        state.rooms = [
          ...state.rooms,
          action.payload
        ];
      })
      .addCase(getMyRooms.fulfilled, (state, action) => {
        state.rooms = action.payload;
      })
      .addCase(enterRoom.fulfilled, (state, action) => {
        state.currentRoom = action.payload
      })
      .addCase(addRoomRule.fulfilled, (state, action) => {
        if (state.currentRoom?.id === action.payload.roomId) {
          state.currentRoom.rules = [
            ...state.currentRoom.rules,
            action.payload.newRule
          ]
        }
      })
      .addCase(addSnitch.fulfilled, (state, action) => {
        if (!state.currentRoom || state.currentRoom?.id !== action.payload.roomId) {
          return;
        }
        
        state.currentRoom = {
          ...state.currentRoom,
          users: state.currentRoom.users.map(u => u.id === action.payload.user.id
            ? action.payload.user
            : u)
        }
      })
  },
})

// export const {} = roomsSlice.actions
export default roomsSlice.reducer;