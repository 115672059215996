import { Container } from 'react-bootstrap';
import { Route, Routes } from 'react-router-dom';
import AppNavbar from './components/navbar/AppNavbar';
import Home from './pages/home/Home';
import Room from './pages/room/Room';

function App() {
  return (
    <>
      <AppNavbar />
      <div style={{paddingTop: "3rem"}}>
        <Container>
          <Routes>
            <Route path="/rooms/:id" element={<Room />} />
            <Route path="*" element={<Home />} />
          </Routes>
        </Container>
      </div>
    </>
  );
}

export default App;
