import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { User } from "../../models/identity.models";
import identityApi, { LoginRequest, RegisterRequest } from "../../api/identity.api";

export interface IdentityState {
  user?: User | null;
  isAuthenticated: boolean;
}

const initialState: IdentityState = {
  isAuthenticated: !!localStorage.getItem("auth_token")
}

export const loginUser = createAsyncThunk<
  User | null,
  LoginRequest
>('identity/login', async (request, { rejectWithValue }) => {
  try
  {
    const tokenResponse = await identityApi.loginUser(request);
    if (tokenResponse?.token) {
      localStorage.setItem("auth_token", tokenResponse.token)
      return await identityApi.getMe();
    }
    return null
  }
  catch (error: any)
  {
    const data = error?.response?.data;
    const errorObject = data?.errors ? data : { errors: data };
    return rejectWithValue(errorObject);
  }
    
})

export const registerUser = createAsyncThunk<
  void,
  RegisterRequest
>('identity/register', async (request, { rejectWithValue }) => {
  try
  {
    return await identityApi.registerUser(request)
  }
  catch (error: any)
  {
    return rejectWithValue(error?.response?.data)
  }
})


export const identitySlice = createSlice({
  name: 'identity',
  initialState,
  reducers: { 
    logout: (state) => { 
      state.user = null;
      state.isAuthenticated = false;
      localStorage.removeItem("auth_token");
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.fulfilled, (state, action) => {
        const user = action.payload;
        state.user = user;
        state.isAuthenticated = !!user;
      })
      .addCase(loginUser.rejected, (state) => {
        state.isAuthenticated = false;
      });
  },
})

export const { logout } = identitySlice.actions
export default identitySlice.reducer