const styles = {
  Card: {
    marginBottom: "2rem",
    padding: "2rem",
    Heading: {
      marginBottom: "2rem"
    },
  }
}

export default styles;