import { useEffect } from "react";
import { Button, Card, ListGroup } from "react-bootstrap";
import { useSelector } from "react-redux";
import { LinkContainer } from "react-router-bootstrap";
import { getMyRooms } from "../../store/features/rooms.slice";
import { RootState, useAppDispatch } from "../../store/store";
import styles from "./RoomList.styles"

export default function RoomList() {
    const { rooms } = useSelector((state: RootState) => state.rooms)
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(getMyRooms())
    }, [dispatch])

    return (
        <Card style={styles.Card}>
            <h1 style={styles.Card.Heading}>My rooms</h1>
            {rooms.length === 0
                ? (<h2>No rooms to display</h2>)
                : (
                    <Card>
                        <ListGroup variant="flush">
                            {rooms.map(room => (
                                <ListGroup.Item style={styles.RoomListItem}>
                                    {room.name}
                                    <LinkContainer
                                        to={`rooms/${room.id}`}
                                        style={styles.RoomListItem.Button}
                                    >
                                        <Button>
                                            Enter
                                        </Button>
                                    </LinkContainer>
                                </ListGroup.Item>
                            ))}
                        </ListGroup>
                    </Card>
                )
            }
        </Card>
    )
}