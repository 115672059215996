import globalStyles from "../../styles/global.styles";

const styles = {
  ...globalStyles,
  RoomListItem: {
    display: "flex",
    alignItems: "baseline",
    Button: {
      marginLeft: "auto"
    }
  }
}

export default styles;