import { useState, useRef, FormEvent } from "react";
import { Card, Form, Row, Col, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { JoinRoomRequest } from "../../api/rooms.api";
import { joinRoom } from "../../store/features/rooms.slice";
import { useAppDispatch } from "../../store/store";
import styles from "../../styles/global.styles";
import FormInput from "../form-input/FormInput";

export default function RoomJoin() {
    const [errors, setErrors] = useState<any>({});
    const [submitAttempted, setSubmitAttempted] = useState(false)

    const form = useRef<HTMLFormElement>(null);
    const dispatch = useAppDispatch();

    const onJoinRoom = async (event: FormEvent) => {
        event.preventDefault();
        const formData = new FormData(form.current as HTMLFormElement);
        const id = formData.get("id") as string

        const request: JoinRoomRequest = {
            id: id.trim() === "" ? null : id,
        }

        await dispatch(joinRoom(request)).unwrap()
            .then((room) => {
                setErrors({})
                toast.info(`Room ${room.name} successfully joined! :)`)
            })
            .catch((err) => {
                setErrors(err.errors);
                toast.error("Room joining failed :(")
            })
            .finally(() => {
                setSubmitAttempted(true);
            })
    }

    return (
        <Card style={styles.Card}>
            <h1 style={styles.Card.Heading}>Join room</h1>
            <Form ref={form} onSubmit={onJoinRoom}>
                <Row>
                    <Col xs={9}>
                        <FormInput
                            placeholder="Room ID"
                            name="id"
                            errors={errors?.Id}
                            submitted={submitAttempted}
                        />
                    </Col>
                    <Col>
                        <Button type="submit" className="mb-2">
                            Join
                        </Button>
                    </Col>
                </Row>
            </Form>
        </Card>
    )
}