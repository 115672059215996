import { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import { AddRoomRuleRequest } from "../../api/rooms.api";
import { addRoomRule } from "../../store/features/rooms.slice";
import { useAppDispatch } from "../../store/store";
import FormInput from "../form-input/FormInput";

export interface Props {
    roomId: string,
    show: boolean,
    onHide: () => void
}

export default function AddRuleModal(props: Props) {
    const [description, setDescription] = useState<string>("");
    const [points, setPoints] = useState<number>(10);
    const [submitAttempted, setSubmitAttempted] = useState<boolean>(false);
    const [errors, setErrors] = useState<any>({});
    const dispatch = useAppDispatch();

    const onAddRule = async () => {
        const request: AddRoomRuleRequest = {
            description,
            points
        }

        const thunkProps = {
            roomId: props.roomId,
            request
        }

        await dispatch(addRoomRule(thunkProps)).unwrap()
            .then(() => {
                props.onHide();
                toast.success("Rule successfully added!");
            })
            .catch((err) => {
                setErrors(err.errors);
            })
            .finally(() => {
                setSubmitAttempted(true);
            })
    }

    return (
        <Modal
            show={props.show}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Add rule
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={e => e.preventDefault()}>
                    <Form.Group className="mb-3">
                        <FormInput
                            label="Description"
                            errors={errors?.Description}
                            submitted={submitAttempted}
                            value={description}
                            onChange={e => setDescription(e.target.value)}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <FormInput
                            type="number"
                            label="Points"
                            errors={errors?.Points}
                            submitted={submitAttempted}
                            value={points}
                            onChange={e => setPoints(e.target.value)}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onAddRule}>Add</Button>
                <Button variant="error" onClick={props.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}