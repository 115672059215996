import { FormEvent, useRef, useState } from "react";
import { Card, Form, Row, Col, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { CreateRoomRequest } from "../../api/rooms.api";
import { createRoom } from "../../store/features/rooms.slice";
import { useAppDispatch } from "../../store/store";
import styles from "../../styles/global.styles";
import FormInput from "../form-input/FormInput";

export default function RoomCreate() {
    const [errors, setErrors] = useState<any>({});
    const [submitAttempted, setSubmitAttempted] = useState(false)
    const form = useRef<HTMLFormElement>(null);
    const dispatch = useAppDispatch();

    const onCreateRoom = async (event: FormEvent) => {
        event.preventDefault();
        const formData = new FormData(form.current as HTMLFormElement);
        const request: CreateRoomRequest = {
            name: formData.get("name") as string,
        }

        await dispatch(createRoom(request)).unwrap()
            .then(() => {
                setErrors({})
                toast.info("Room successfully created! :)")
            })
            .catch((err) => {
                setErrors(err.errors);
                toast.error("Room creation failed :(")
            })
            .finally(() => {
                setSubmitAttempted(true);
            })
    }

    return (
        <Card style={styles.Card}>
            <h1 style={styles.Card.Heading}>Create room</h1>
            <Form ref={form} onSubmit={onCreateRoom}>
                <Row>
                    <Col xs={9}>
                        <FormInput
                            placeholder="Room name"
                            name="name"
                            errors={errors?.Name}
                            submitted={submitAttempted}
                        />
                    </Col>
                    <Col>
                        <Button type="submit" className="mb-2">
                            Create
                        </Button>
                    </Col>
                </Row>
            </Form>
        </Card>
    )
}