import axios, { AxiosRequestConfig } from "axios";

const http = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }
})

http.interceptors.request.use((config: AxiosRequestConfig) => {
  const token = localStorage.getItem("auth_token");
  if (!!token) {
    config.headers = {
      ...config.headers,
      'Authorization': `Bearer ${token}`
    }
  }
  return config;
})

export default http;