import { configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import identityReducer from "./features/identity.slice"
import roomsReducer from "./features/rooms.slice"

export const store = configureStore({
  reducer: {
    identity: identityReducer,
    rooms: roomsReducer
  }
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>();