import { FormEvent, useRef, useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { RegisterRequest } from "../../../api/identity.api";
import FormInput from "../../../components/form-input/FormInput";
import { registerUser } from "../../../store/features/identity.slice";
import { useAppDispatch } from "../../../store/store";
import styles from "./Register.styles"

export default function Register() {
    const [ errors, setErrors ] = useState<any>({});
    const [ submitAttempted, setSubmitAttempted ] = useState(false)
    const form = useRef<HTMLFormElement>(null);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    async function submitRegister(event: FormEvent) {
        event.preventDefault();
        const formData = new FormData(form.current as HTMLFormElement);
        const request: RegisterRequest = {
            username: formData.get("username") as string,
            password: formData.get("password") as string,
            passwordRepeat: formData.get("passwordRepeat") as string
        }

        await dispatch(registerUser(request)).unwrap()
            .then(() => {
                navigate("/auth/login")
            })
            .catch((err) => {
                setErrors(err.errors);
                toast.error("No redirect for you! :(")
            })
            .finally(() => {
                setSubmitAttempted(true);
            })
    }

    return (
        <Card style={styles.LoginWindow}>
            <Form ref={form} onSubmit={submitRegister}>
                <Form.Group className="mb-3">
                    <FormInput
                        type="text"
                        label="Username"
                        name="username"
                        errors={errors?.UserName}
                        submitted={submitAttempted}
                    />
                </Form.Group>
                <Form.Group className="mb-3">
                    <FormInput
                        type="password"
                        label="Password"
                        name="password"
                        errors={errors?.Password}
                        submitted={submitAttempted}
                    />
                </Form.Group>
                <Form.Group className="mb-3">
                    <FormInput
                        type="password"
                        label="Repeat Password"
                        name="passwordRepeat"
                        errors={errors?.PasswordRepeat}
                        submitted={submitAttempted}
                    />
                </Form.Group>
                <Button variant="primary" type="submit">
                    Register
                </Button>
                <span className="ml-auto">
                    Already have an account?
                    <Link to="/auth/login">Log In</Link>
                </span>
            </Form>
        </Card>
    )
}