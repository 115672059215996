import { LoginResponse, User } from "../models/identity.models";
import http from "./http";

export interface LoginRequest {
  username: string,
  password: string,
}

function loginUser(request: LoginRequest): Promise<LoginResponse> {
  return http.post<LoginResponse>("/identity/login", request)
    .then(res => res.data)
}

export interface RegisterRequest {
  username: string,
  password: string,
  passwordRepeat: string
}

function registerUser(request: RegisterRequest): Promise<void> {
  return http.post<void>("/identity/register", request)
    .then(res =>res.data)
}

function getMe(): Promise<User> {
  return http.get<User>("/identity/me").then(res => res.data)
}

const identityApi = {
  loginUser,
  registerUser,
  getMe
}

export default identityApi;