const styles = {
  RoomName: {
    fontSize: "3rem",
    fontWeight: "bolder"
  },
  RoomId: {
    marginBottom: "2rem",
    display: "flex",
    'flex-direction': "row",

    Label: {
      fontSize: "1.25rem",
      fontWeight: "bolder",
      marginRight: ".5rem"
    },
    Value: {
      alignSelf: "center",
      color: "#AAAAAA"
    }
  }
}

export default styles;