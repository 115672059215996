import { Modal, Button, Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { AddSnitchRequest } from "../../api/snitches.api";
import { addSnitch } from "../../store/features/rooms.slice";
import { RootState, useAppDispatch } from "../../store/store";

export interface Props {
    userId?: string,
    show: boolean,
    onHide: () => void
}

export default function AddSnitchModal(props: Props) {
    const rules = useSelector((state: RootState) => state.rooms.currentRoom?.rules)
    const roomId = useSelector((state: RootState) => state.rooms.currentRoom?.id)
    const dispatch = useAppDispatch();

    const onAddSnitch = async (ruleId: string) => {
        const request: AddSnitchRequest = {
            userId: props.userId as string,
            roomId: roomId as string,
            ruleId: ruleId
        }

        await dispatch(addSnitch(request)).unwrap()
            .then(() => {
                props.onHide();
                toast.success("Rule successfully added!");
            })
            .catch((err) => {
                toast.error("Failed to add snitch :(");
            })
    }

    return (
        <Modal
            show={props.show}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Snitch!
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {!rules || rules.length === 0
                    ? (<h2>No rules in the room yet</h2>)
                    : (
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>Points</th>
                                    <th>Description</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {rules.map(rule => (
                                    <tr key={rule.id}>
                                        <td>{rule.points}</td>
                                        <td>{rule.description}</td>
                                        <td>
                                            <Button 
                                                style={{ width: "100%" }}
                                                onClick={() => onAddSnitch(rule.id)}
                                            >
                                                Add
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    )
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="error" onClick={props.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}