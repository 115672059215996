import { RoomUserSnitch } from "../models/rooms.models";
import http from "./http";

export interface AddSnitchRequest {
  userId: string,
  roomId: string,
  ruleId: string
}

function addSnitch(request: AddSnitchRequest): Promise<RoomUserSnitch> {
  return http.post<RoomUserSnitch>("/snitches", request).then(res => res.data);
}

const snitchesApi = {
  addSnitch,
}

export default snitchesApi;