import { Col, Row } from "react-bootstrap";
import RoomCreate from "../../components/room-create/RoomCreate";
import RoomJoin from "../../components/room-join/RoomJoin";
import RoomList from "../../components/room-list/RoomList";

export default function Home() {
  return (
    <Row>
      <Col>
        <RoomList />
      </Col>

      <Col>
        <RoomJoin />
        <RoomCreate />
      </Col>
    </Row>
  );
}
